import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const maplume = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Ma plume" />
    <h3 className='underline-title'>Ma plume</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        O! ma plume bien aimée!<br />
        Ils te croyaient assez muselée<br />
        Ton silence stagiaire faisait penser<br />
        Que plus jamais tu n'écrirais<br />
      </p>
      <p>
        O! ma plume adorée!<br />
        Garde encore ta bleue couleur<br />
        Signe d'espoir et de verdeur<br />
        À la longue seras plus rodée<br />
      </p>
      <p>
        O! ma plume chérie!<br />
        Ils te rêvaient prisonnière<br />
        Tu n'es qu'une simple cavalière<br />
        Bien trop jeune pour périr<br />
      </p>
      <p>
        O! jeune plume esseulée!<br />
        Souveraine indifférence <br />
        Ton vol solitaire et plané<br />
        Provoque mépris et médisance<br />
      </p>
      <p>
        O! silencieuse plume!<br />
        Sédentaire et tetue comme l'enclume<br />
        Exaspérante car tu ne bronches<br />
        Quand tu es martelée comme muette roche<br />
      </p>
      <p>
        O! ma plume naturelle!<br />
        Tu cesserais d'être personnelle<br />
        Si tu n'étais cet odieux être<br />
        Qu'on te reproche vainement d'être<br />
      </p>
      <p>
        Plume verte plume bleue!<br />
        Plume sage plume assoiffée<br />
        De connaissances parbleu!<br />
        Possible que tu deviennes dorée!<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default maplume
